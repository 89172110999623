import React, { useState, useEffect, useRef} from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { isMobile,isAndroid,isIOS } from 'react-device-detect';
import LivenessAnimation from "./LivenessAnimation";
import CreatePassButton from "./CreatePassButton";
import Cookies from "js-cookie";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Spinner,
} from "reactstrap";
import socketIOClient from "socket.io-client";
import axios from "axios";
import DigitInput from "./DigitInput";
import "../css/index.css";
import TimerOverlay from './TimerOverlay';

const WebcamCapture = ({
  initialized,
  setInitialized,
  firstFaceDescriptor,
  setFirstFaceDescriptor,
  email,
  setEmail,
  images,
  setImages,
  timeLeft,
  setTimeLeft,
  timeColor,
  setTimeColor,
  dl,
  setDl,
  messages,
  setMessages,
  color,
  setColor,
  backgroundColor,
  setBackgroundColor,
  logo,
  setLogo,
  currentUrl,
  setCurrentUrl,
  referrer,
  setReferrer,
  isUserCameraAvailable,
  setIsUserCameraAvailable,
  isEnvironmentCameraAvailable,
  setIsEnvironmentCameraAvailable
}) => {
  let navigate = useNavigate();

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const ENDPOINT = process.env.REACT_APP_API_URL;
  const [animationStart, setAnimationStart] = useState(false);
  const [capturing, setCapturing] = useState(null);
  const [showCam, setShowCam] = useState(true);
  const [facingMode, setFacingMode] = useState("user"); // 'user' for front camera, 'environment' for rear camera
  const [message, setMessage] = useState("");
  const [props, setProps] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const { uniqueId } = useParams();
  const [idImage, setIdImage] = useState("");
  const [idData, setIdData] = useState(null);
  const [idMatch, setIDMatch] = useState(null);
  const [faceMatch, setFaceMatch] = useState(null);
  const [numberVerified, setNumberVerified] = useState(null);
  const numberVerifiedRef = useRef(null);
  const [challengePassed, setChallengePassed] = useState(false);
  const selfieRef = useRef(null);
  const aliveRef =useRef(null);
  const selfieImage = useRef(null);
  const retakeSelfie = useRef(null);
  const [selfie, setSelfie] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [correctNumber, setCorrectNumber] = useState("1234");
  const [visible, setVisible] = useState(true);
  const [terminate, setTerminate] = useState(false);
  const terminateRef = useRef(false);
  const [endSession, setEndSession] = useState(false);
  const guideRef = useRef(false);
  const isExpiredRef = useRef(false);
  let countdownRef = useRef(3);
  let doccaptureRef = useRef(false);
  let emailVerifiedRef = useRef(null);
  let firstDetectionMadeRef = useRef(false);
  const [areBothCamerasAvailable, setAreBothCamerasAvailable] = useState(null);
  let selfieLimitRef = useRef(3);
  const documentMessageRef= useRef("Have your US Passport or US Green Card or US State ID ready.")
  const sameDeviceRef = useRef(null);
  const numberChallengeRef = useRef(false);
  const showLoader= useRef(false);
  let animationId = useRef(null);
  const showOutlineRef = useRef(false);
  const guidRef = useRef(null);
  const redirectRef = useRef(null);
  const deviceRef = useRef('');
  const [invalidMessage, setInvalidMessage]=useState('');
  const messagesRef = useRef([]);
  const languageRef= useRef("en");
  const logoRef= useRef(null);
  const selfiecamRef = useRef(null);
  const verifiedIdRef = useRef(null);
  const qrCodeRef = useRef(false);

  useEffect(() => {
    if (isAndroid) {
      deviceRef.current = 'android';
    } else if (isIOS) {
      deviceRef.current = 'ios';
    } else {
      deviceRef.current = 'unknown';
    }
    
  }, []);


  const closeOverlay = () => {
    setShowOverlay(false);
  };

  const videoConstraints = {
    width: 640 * 2,
    height: 480 * 3,
    facingMode: facingMode,
  };


  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };
  
  const wait = async (time) => {
  
    await delay(time); // Wait for 1 second
  };

  const checkCameras = async () => {
    let userCameraAvailable = false;
    let EnvironmentCameraAvailable = false;

    try {
      // Check for user (front-facing) camera
      await navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } });
      userCameraAvailable = true;
      setIsUserCameraAvailable(true);
    } catch (userError) {
     // userCameraAvailable = false;
      console.error("User camera access error:", userError);
      setIsUserCameraAvailable(false);
    //  return true;
    }
  
    try {
      // Check for environment (rear-facing) camera
      await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } });
      EnvironmentCameraAvailable = true;
      setIsEnvironmentCameraAvailable(true);
    } catch (environmentError) {
      console.error("Environment camera access error:", environmentError);

      EnvironmentCameraAvailable = true;
      // Fallback to enumerateDevices if the exact environment camera check fails
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const rearCamera = videoDevices.find(device => device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear'));
        EnvironmentCameraAvailable = Boolean(rearCamera);
        setIsEnvironmentCameraAvailable(EnvironmentCameraAvailable);
      } catch (enumerateError) {
        console.error("Error enumerating devices:", enumerateError);
        setIsEnvironmentCameraAvailable(false);
       // return true;
      }
    }
  
    // Set true if both cameras are available, false otherwise
    //return userCameraAvailable && environmentCameraAvailable;
    return {userCameraAvailable:userCameraAvailable, environmentCameraAvailable:EnvironmentCameraAvailable};
    console.log("Front camera available:", userCameraAvailable);
    console.log("Rear camera available:", EnvironmentCameraAvailable);
  };
  

  function getMessageByKey(messages1, key) {
    // console.log(messages1);
    // console.log(key);
    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }

  useEffect(() => {
    setCurrentUrl(window.location.href);
    Cookies.set('url', window.location.href, { expires: 1 });
  }, []);
  // useEffect(() => {
  //   checkCameras();
  // }, []); // Empty dependency array to ensure this runs once on mount
  

  useEffect(() => {
    if (initialized) {
      const intervalId = setInterval(() => {
        setVisible(prevVisible => !prevVisible);
        setTimeLeft(prevTime => {
          if (prevTime <= 0) {
            clearInterval(intervalId); // Stop the countdown
            return 0;
          }
          return prevTime - 1000; // Decrease by one second
        });
        if (timeLeft < 60000 && timeLeft >= 30000) {
          setTimeColor("yellow");
        }
        if (timeLeft < 30000) {
          setTimeColor("red");
        }
        if (timeLeft === 0) {
          terminateRef.current = true;
          setTerminate(true);
        }

        if ((numberVerifiedRef.current === false && numberChallengeRef.current) || terminateRef.current === true) {
          setTimeLeft(0);
          return () => clearInterval(intervalId);
        }

      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on component unmount
    }
  }, [initialized, setTimeColor, timeLeft, setTimeLeft]);

  const onVerified = (result) => {
    if (result === true) {
      // setMessage(
      //   <div>
      //      <p style={{textAlign:"left"}}>
      //   <ul>
      //   <li>Ensure your face is well-lit and clearly visible.</li>
      //     <li>Remove hats, eyeglasses, or any other personal items.</li>
      //     <li>Align your face to the outline as best as possible.</li>
      //     {props.type === 'registration' && <li>{documentMessageRef.current}</li>}
      //     <li>Press Start to take a selfie.</li>
      //   </ul>
        
      //   </p>
      // </div>
      // );
      setMessage(getMessageByKey(messages,"codeverified")||"Code verified");
      setNumberVerified(true);
      numberVerifiedRef.current = true;
      showOutlineRef.current =true;
      showGuide();
    } else {
      // setMessage(`You failed all three attempts.This session is being terminated`);
      setMessage(getMessageByKey(messages,"codefailed")|| "You failed all three attempts.This session is being terminated");
      setInvalidMessage("codeverificationfailed");
      setNumberVerified(false);
      numberVerifiedRef.current = false;
      stopCapture();
    }
  };
  // useEffect(() => {
  //   const videoElement = document.getElementById("selfiecam");
  //   const targetElement = document.querySelector('.cardbody');
  
  //   if (!videoElement || !targetElement) {
  //     console.error('Required elements not found');
  //     return;
  //   }
  
  //   const handleLoadedMetadata = () => {
  //     console.log('Video metadata loaded');
  //     targetElement.style.top = `${videoElement.clientHeight + 20}px`;
  //     targetElement.style.display = 'block';
  //   };
  
  //   // Check if video metadata is already loaded
  //   if (videoElement.readyState >= 1) {
  //     handleLoadedMetadata();
  //   } else {
  //     // Add event listener for loadedmetadata
  //     videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
  //   }
  
  //   // Cleanup function to remove event listener if necessary
  //   return () => {
  //     videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
  //   };
  // }, [webcamRef.current]);
  


  const handleDataSubmit = (result) => {
    setIDMatch(result);
    setMessage(idMatch ? "Wow, you know your ID by Heart!" : "We are not able to verify your information.");
    setEndSession(true);
  };
  function extractGuidFromUrl(url) {
    // Create a URL object
    const parsedUrl = new URL(url);
    
    // Get the pathname part of the URL
    const pathname = parsedUrl.pathname;
  
    // Regular expression to match the GUID
    const regex = /\/([a-fA-F0-9\-]{36})\/faceimage.jpg$/;
  
    // Extract the GUID using the regex
    const match = pathname.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = () => {
      window.history.go(1);
    };
  }, []);

  useEffect(() => {
    
    
     
      const socket = socketIOClient(ENDPOINT, {
        withCredentials: true,
        transports: ["websocket", "polling"],
        query: { uniqueId },
        secure: true
      });
      socket.on('connect_error', (error) => {
        setEmailVerified(false);
        emailVerifiedRef.current = false;
      });

      socket.on('connect_timeout', (timeout) => {
        setEmailVerified(false);
        emailVerifiedRef.current = false;
      });

      socket.on('error', (error) => {
        setEmailVerified(false);
        emailVerifiedRef.current = false;
      });
      socket.on("connect", () => {
        if (emailVerifiedRef.current === null) {
          socket.emit("EmailVerified", { uniqueId });
          const user = Cookies.get('user');
          console.log(user);
          const lang = navigator.language.split('-')[0];
          socket.emit("GetWebProps", { "uniqueId": uniqueId, "language": lang, "samedevice": user ? true : false });
          Cookies.set('user', '', { expires: -1 });
          console.log("gwerwe")
          socket.on("webprops", (data) => {
            console.log("Datamess",data)
            if(data && data.messages){
              setMessages(data.messages);
            }
            if(data && data.props && data.props.referrer){
              setReferrer(data.props.referrer);
            }
            if(data && data.props && data.props.qrCode){
              qrCodeRef.current = data.props.qrCode;
            }
            if(data && data.props && data.props.logo){  
              logoRef.current = data.props.logo;
              setLogo(data.props.logo);
            }
            if (data && data.props && data.props.color) {
              setColor(data.props.color);
              
              document.documentElement.style.setProperty('--custom-color', data.props.color);
              
            }
            if (data && data.props && data.props.backgroundcolor) {
              setBackgroundColor(data.props.backgroundcolor);
              document.documentElement.style.setProperty('--custom-bg-color', data.props.backgroundcolor);
              
            }

            // if(data && data.props && data.props.redirectUrl){
            //     setRedirectUrl(data.props.redirectUrl);
            
            checkCameras().then((camerasAvailable) => {

              if (camerasAvailable.userCameraAvailable) {
                setAreBothCamerasAvailable(true); // Assuming you have a state setter for this
                if(camerasAvailable.environmentCameraAvailable){
                  setIsEnvironmentCameraAvailable(true);
                }
                console.log(camerasAvailable);
                setEmailVerified(true);
                emailVerifiedRef.current = true;
            
            if (data && data.props) {
              console.log("Dataprops",data.props)
              if (data.props.type === "login") {
                if (data.props.verified) {
                  setTimeLeft(300000);
                  setIdImage(data.props.readUrl);
                  guidRef.current = extractGuidFromUrl(data.props.readUrl);
                } else {
                  setTerminate(true);
                  terminateRef.current = true;
                }
              } 
              
              // else {

              //   if(data.props.documents && data.props.documents.length>0){
              //     documentMessageRef.current = `Have your ${data.props.documents.join(" or ")} ready.`
              //   }
              //   if(data.props.states && data.props.states.length>0){
              //     documentMessageRef.current.replace("US State ID", "US State ID(" + data.props.states.join(","));
              //   }
              //   if(data.props.isExpired){
              //     documentMessageRef.current = "ID Expired:Re-registration required. " + documentMessageRef.current;
              //   }

              // }
              // const limit = data.props.type === "login" ? "2" : "4";
              // const vType = data.props.type === "login" ? "Sign-In" : "Registration";
              if(user){
                sameDeviceRef.current = true;
              }

              setEmailVerified(true);
              emailVerifiedRef.current = true;
              const loadModels = async (propsdata) => {
                setInitialized(true);
                setEmail(propsdata.props.email);
                setProps(propsdata.props);
                if (propsdata.props.dl) {
                  setDl(propsdata.props.dl);
                }
                if (propsdata.props.challenge && propsdata.props.challengeNumber) {
                  console.log(propsdata.props);
                  numberChallengeRef.current = true;
                  setCorrectNumber(propsdata.props.challengeNumber);
                  // if(user){
                  setTimeout(()=> {
                  showCode(propsdata.props.challengeNumber.toString(),propsdata.props.color);
                  setShowOverlay(true);
                },1000);
              // } 
                } else {
                  showOutlineRef.current =true;
                
                  
                }
          
                if (numberChallengeRef.current) {
                  // setMessage(
                  //   <div >
                  //     <p style={{textAlign:"left"}}>
                  //     <ul>
                  //       <li>You have {limit} minutes to complete the {vType} verification.</li>
                  //       <li>Press the highlighted box to get started.</li>
                  //       <li>The boxes will be highlighted in a random order.</li>
                  //       <li>The order of digits in the boxes needs to match the order of the digits displayed on the screen.</li>
                  //     </ul>
                  //     </p>
                  //   </div>
                  // );
                  console.log("Startwithcode1111111", data.messages);
                  setMessage(getMessageByKey(data.messages,"startwithcode"));
                } else {
                  // setMessage(
                  //   <div>
                  //      <p style={{textAlign:'left'}}>
                  //     <ul>
                  //       <li>You have {limit} minutes to complete the {vType} verification.</li>
                  //       <li>Ensure your face is well-lit and clearly visible.</li>
                  //       <li>Remove hats, eyeglasses, or any other personal items.</li>
                  //       <li>Align your face to the outline as best as possible.</li>
                  //       {propsdata.props.type === 'registration' && <li>{documentMessageRef.current}</li>}
                  //       <li>Press Start to take a selfie.</li>
                  //     </ul>
                  //     </p>
                  //   </div>
                  // );
                  setMessage(getMessageByKey(data.messages,"startwithoutcode"));
                  setTimeout(()=>{
                    showGuide();
                  } ,1000);
                }
                if (propsdata.props.timer) {
                  setTimeLeft(propsdata.prop.timer);
                }

              };
              loadModels(data);
            } else {
             
              setEmailVerified(false);
              emailVerifiedRef.current = false;
            }
          } else {
            setAreBothCamerasAvailable(false);
          }
        });
          });
        }

        socket.on('updateClients', (data) => {
          // alert(JSON.stringify(data));
          if (data && data.uniqueId === uniqueId) {
        
            if (data.verified === true) {

            

              sameDeviceRef.current = data.samedevice;
              if (data.redirect) {
               // alert(JSON.stringify(data));
                redirectRef.current=data.redirect;
                
                // setTimeout(()=>{
                //   window.location.href = data.redirect;
                //   socket.disconnect();
                // },10000)

              } else {

                const event = new CustomEvent('IdDataReady', { detail: { idData: data } });
                document.dispatchEvent(event);
                socket.disconnect();
              }
            } else if (data && data.verified === false) {
              sameDeviceRef.current = data.samedevice;
              // setTimeout(function () {
              //   if (data.redirect && data.samedevice) {
              //     window.location.href = data.redirect;
              //   }
              //   socket.disconnect();
              // }, 1000);
            } else if (data && data.reset) {
              socket.disconnect();
            }
          }
        });

        if (numberVerified === true && !challengePassed) {
          socket.emit("ChallengeVerified", { uniqueId });
          setChallengePassed(true);
        }

        if (terminate || terminateRef.current === true || numberVerified === false || numberVerifiedRef.current === false) {
          socket.emit("SessionTerminated", { uniqueId:uniqueId, reason:invalidMessage, type: props.type })
        }

        if (firstFaceDescriptor) {
          socket.emit("realPersonVerified", { uniqueId });
        }

        if (idMatch === true) {
          socket.emit("IDMatch", { uniqueId });
          setEndSession(true);
        }
        if (idMatch === false && selfieLimitRef.current === 0) {
          socket.emit("IDNotMatch", { uniqueId : uniqueId, reason: invalidMessage});
          setEndSession(true);
        }
      });

      return () => {
        socket.disconnect();
      };
    
  }, [uniqueId, firstFaceDescriptor, emailVerifiedRef.current, idMatch, numberVerified]);

  const captureSelfie = () =>{
    if (webcamRef.current) {
   

      const screenshot = webcamRef.current.getScreenshot();
      console.log(screenshot.split(',')[1]);
      showOutlineRef.current = false;
      selfieImage.current = screenshot;
      selfieRef.current = true;
      // setMessage("Ensure your face is well-lit and clearly visible. If needed, press 'Re-take' to capture a new selfie, or press 'Validate' to proceed")
      setMessage(getMessageByKey(messages,"captureselfie"));
    }
  }

  const checkSelfie = async () => {
      aliveRef.current = true;
       selfieLimitRef.current--;
       console.log(selfieLimitRef.current);
      if (selfieImage.current) {
        try {
            //setAnimationStart(true);
            console.log(selfieImage.current.split(',')[1])
          // setMessage("Analyzing Selfie for Liveness");
          setMessage(getMessageByKey(messages,"checkselfie"));
          showLoader.current = true;
          const options = {
            method: 'POST',
            url: ENDPOINT + '/api/check-liveness',
            headers: {
              'content-type': 'application/json',
              'Authorization': `Bearer ${props.apiKey}`
            },
            data: {uniqueid : uniqueId, image: selfieImage.current.split(',')[1]}
          };
          console.log(options);
          const response = await axios.request(options);
          console.log(response.data);
          if (response.data.result) {
            setFirstFaceDescriptor(true);
            selfieImage.current = `data:image/jpeg;base64,${response.data.potraitImage}`;
            setImages({
              originalImage: "",
              detectedImage: "",
              faceImage: `data:image/jpeg;base64,${response.data.croppedImage}`,
              potraitImage: `data:image/jpeg;base64,${response.data.potraitImage}`
            });

            proceedWithVerification(response.data.croppedImage);
          } else {
           // setAnimationStart(false);
            console.log("Limit",selfieLimitRef.current);
            setInvalidMessage("livenessdetectionfailed");
            if(selfieLimitRef.current <= 0) { 
              setTerminate(true);
            }
            showLoader.current = false;
            aliveRef.current = false;
           // setMessage(`Liveness detection failed. Re-take selfie and try again. Number of tries left: ${selfieLimitRef.current}`);
           setMessage(getMessageByKey(messages,"livenessfail") +  selfieLimitRef.current);  
            
          }
        } catch (error) {
          console.error(error);
         // setAnimationStart(false);
          if(selfieLimitRef.current <= 0) {
            setInvalidMessage("livenessdetectionfailed");
            setTerminate(true);
          }
            showLoader.current = false;
          aliveRef.current = false;
       //   setMessage(`Liveness detection failed. Re-take selfie and try again. Number of tries left: ${selfieLimitRef.current}`);
       setMessage(getMessageByKey(messages,"livenessfail") +  selfieLimitRef.current);  
          
        }
      }
    
  };

  const proceedWithVerification = async (image) => {


    doccaptureRef.current = true;

    if (props.type === "login") {
     // setMessage("Comparing selfie to ID on file");
     setMessage(getMessageByKey(messages,"logincompare"));  
      const isIdMatch = await checkId(image);
      setFaceMatch(isIdMatch);
      setIDMatch(isIdMatch);
     // setAnimationStart(false);
      showLoader.current = false;
      if (isIdMatch) {

        // setMessage(`ID verified`);
        setMessage(getMessageByKey(messages,"idverified"));  
        
        
      } else {
        setInvalidMessage("selfiematchfailed");
        // setMessage(`ID not verified`);
        setMessage(getMessageByKey(messages,"idnotverified")); 
        // setTimeout(()=>{
        //   setEndSession(true);
        // },2000);
        
      }
    } else {
     // setAnimationStart(false);
      showLoader.current = false;
      // setMessage(
      //   <div style={{textAlign:"left"}}>
      //   <ul >
      //     <li>Next step is ID Capture and Verification</li>
      //     <li>Make sure you are in a well-lit area.</li>
      //     <li>Place your ID on a dark surface</li>
      //   <li>{documentMessageRef.current}</li>
      //     <li>Press Next.</li>
      //   </ul>
      // </div>
      // );
      // showFrame();
      setMessage(getMessageByKey(messages,"idcapturestep")); 
    }


    
  };


function handleRedirect(){
  window.location.href=redirectRef.current;
}


  async function urlToBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  async function checkId(firstFaceImageBase64) {
    try {
      const idImageBase64 = await urlToBase64(idImage);

      const options = {
        method: 'POST',
        url: ENDPOINT + '/api/compare-face',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${props.apiKey}`

        },
        data: {
          uniqueid : uniqueId, 
          image1: firstFaceImageBase64,
          image2: idImageBase64 // Assuming idImage is a base64-encoded image string
        }
      };

      const response = await axios.request(options);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error checking ID:', error);
      return false;
    }
  }

  function openVerifiedID(){
    window.open(verifiedIdRef.current);
  }

  function showGuide() {
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas element is not available.");
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;  
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Define oval parameters as a percentage of the canvas size
        const ovalWidth = canvas.width * 0.50;  // 60% of the canvas width
        const ovalHeight = canvas.height * 0.70;  // 40% of the canvas height
        const ovalX = canvas.width / 2;  // Center of the canvas (X-axis)
        const ovalY = canvas.height / 2;  // Center of the canvas (Y-axis)
    
        // Set line style for the oval
        ctx.strokeStyle = '#999999';  // Light white outline
        ctx.lineWidth = 4;
        ctx.setLineDash([10, 5]);  
    
        // Draw the oval
        ctx.beginPath();
        ctx.ellipse(ovalX, ovalY, ovalWidth / 2, ovalHeight / 2, 0, 0, 2 * Math.PI);
        ctx.stroke();
    
        // // Optionally, add an "eyes level" line across the oval
        // const eyeLevelY = ovalY - (ovalHeight / 4);  // Eyes line, slightly above the center
        // ctx.beginPath();
        // ctx.moveTo(ovalX - ovalWidth / 2, eyeLevelY);
        // ctx.lineTo(ovalX + ovalWidth / 2, eyeLevelY);
        // ctx.strokeStyle = 'rgba(255, 255, 255, 0.6)';
        // ctx.lineWidth = 2;
        // ctx.stroke();


  }  


  function showFrame() {

    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas element is not available.");
      return;
    }
    const ctx = canvas.getContext('2d');
    
        ctx.strokeStyle = '#999999';  // Light white outline
        ctx.lineWidth = 1;
        ctx.setLineDash([10, 5]);  
    
  // Define square dimensions and position
  const squareSize = canvas.width * .75;   // Size of the square (400x400 pixels)
  const squareX = (canvas.width - squareSize) / 2;  // Center horizontally
  const squareY = (canvas.height - squareSize) / 2;  // Center vertically

  // Draw the dashed square
  ctx.strokeRect(squareX, squareY, squareSize, squareSize);
    

  }  




  function showCode(code, codeColor) {
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas element is not available.");
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight; 
    const ctx = canvas.getContext('2d');
    if (ctx && numberVerifiedRef.current === null && code !== null && code !== undefined && code !== "") {
      const spacing = isMobile ? 100 :25;
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawing
      ctx.globalAlpha = 0.9; // Adjust for desired transparency
      ctx.shadowColor = 'black';
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 1;
      ctx.shadowOffsetY = 1;
      ctx.fillStyle = codeColor; // Set text color
      ctx.font = isMobile ? '200px serif': '25px serif'; // Set font size and style
      ctx.textAlign = "center";
      let totalWidth = 0;
      code.split('').forEach((digit, index) => {
        totalWidth += ctx.measureText(digit).width;
        if (index < code.length - 1) totalWidth += spacing; // Add spacing except after the last digit
      });
      const startX = (canvas.width - totalWidth) / 2;
      let currentX = startX; // Current drawing X position
      const startY = isMobile ? 200 : 50;
      code.split('').forEach(digit => {
        ctx.fillText(digit, currentX, startY);
        currentX += ctx.measureText(digit).width + spacing;
      });
    } else if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  const startCapture = async () => {
    // setMessage("Taking Selfie");
  //   showGuide();
  //  await wait(2000);
    setMessage(getMessageByKey(messages,"abouttoselfie")); 
    retakeSelfie.current = true;
    aliveRef.current = false;
    setFirstFaceDescriptor(null);
    selfieImage.current = null;
    doccaptureRef.current = false;
    countdownRef.current = 3;
    selfieRef.current = null;
    showOutlineRef.current = true;
    setImages({
      originalImage: "",
      detectedImage: "",
      faceImage: "",
      potraitImage: ""
    });
    setFacingMode("user");
    setShowCam(true);
    setCapturing(true);
    await wait(500);
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;
    if (video && canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const contextCD = canvas.getContext('2d');
      const ovalWidth = canvas.width * 0.50;  // 60% of the canvas width
      const ovalHeight = canvas.height * 0.70;  // 40% of the canvas height
      const ovalX = canvas.width / 2;  // Center of the canvas (X-axis)
      const ovalY = canvas.height / 2;  // Center of the canvas (Y-axis)
  
      // Set line style for the oval
      contextCD.strokeStyle = '#999999';  // Light white outline
      contextCD.lineWidth = 4;
      contextCD.setLineDash([10, 5]);  
  
      // Draw the oval
      contextCD.beginPath();
      contextCD.ellipse(ovalX, ovalY, ovalWidth / 2, ovalHeight / 2, 0, 0, 2 * Math.PI);
      contextCD.stroke();

      const drawGuideAndCountdown = () => {
        // Clear the canvas
      
        // Calculate the rectangle area to clear within the oval
        const countdownBoxWidth = ovalWidth * 0.6; // A smaller rectangle within the oval width
        const countdownBoxHeight = ovalHeight * 0.3; // A smaller rectangle within the oval height
        const countdownBoxX = ovalX - (countdownBoxWidth / 2); // Center horizontally inside the oval
        const countdownBoxY = ovalY - (countdownBoxHeight / 2); // Center vertically inside the oval
      
        // Clear only the area where the countdown is displayed, not the entire canvas
        contextCD.clearRect(countdownBoxX, countdownBoxY, countdownBoxWidth, countdownBoxHeight);
      
        
    
        
        // Draw countdown number
        contextCD.font = '200px San-Serif';
        contextCD.fillStyle = color;
        contextCD.textAlign = 'center';
        contextCD.fillText(countdownRef.current.toString(), canvas.width / 2, canvas.height / 2);
      };




   
      const countdownInterval = setInterval(() => {
  
        drawGuideAndCountdown();

        if (countdownRef.current === 0) {
          clearInterval(countdownInterval);
          // Implement the picture taking effect
          contextCD.fillStyle = 'rgba(255, 255, 255, 0.2)';
          contextCD.fillRect(0, 0, canvas.width, canvas.height);
          setTimeout(() => {
            // Clear the effect and take the screenshot
            contextCD.clearRect(0, 0, canvas.width, canvas.height);
            captureSelfie();
          }, 500); // Adjust delay for the effect to be noticeable
        } else {
          // setMessage("Taking a selfie. Continue looking at the camera");
          setMessage(getMessageByKey(messages,"takingselfie")); 
          countdownRef.current--;
        }
      }, 1000); // 1000 milliseconds for each countdown step
    } else {
      console.error("Video or canvas element is not available.");
    }
  };

  const stopCapture = () => {
    setCapturing(false);
  };

  return (
    <div>
      {initialized && <TimerOverlay timeLeft={timeLeft} color="green" timeColor={timeColor} visible={visible} />}

      {isEnvironmentCameraAvailable &&<div className="landscape-warning">
        <img className="logo" src={logo} alt="checkid" />
        <p style={{ margin: '5px' }}> {getMessageByKey(messages,"landscape")}</p>
      </div>}

      {(terminate || (numberChallengeRef.current && numberVerifiedRef.current === false) || terminateRef.current === true || timeLeft <= 0) && (
        <div className="session-starting">
          <img className="logo" src={logo} alt="checkid" />
          <p style={{ margin: '5px' }}>{getMessageByKey(messages,"terminated")}</p>
          {/* <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = currentUrl}>Try again</Button></p> */}

        </div>
      )}
      {endSession &&   (
        <div className="session-starting">
          <img className="logo" src={logo} alt="checkid" />
          {/* {sameDeviceRef.current && <p><div className="init-loader"></div></p>} */}
          {idMatch ? <p style={{ margin: '5px' }}>{getMessageByKey(messages,"idverified")}</p> : <p style={{ margin: '5px' }}>{getMessageByKey(messages,"idnotverified")}</p>}
          {idMatch && qrCodeRef.current && deviceRef.current!=="unknown" &&  <p style={{margin:'5px'}}><CreatePassButton uniqueId={guidRef.current} apiKey={props.apiKey} device={deviceRef.current} messages={messages} color={color} backgroundColor={backgroundColor}/></p>}
          {/* {idMatch && verifiedIdRef.current && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Verified ID" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={openVerifiedID}>Add Verified ID</Button></p>}  */}

          {idMatch === false && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Start over" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}}  onClick={() => window.location.href = currentUrl}>Try again</Button></p>}
          {sameDeviceRef.current  ? 
            <p style={{ margin: '5px' }}>  <Button tabIndex="0" disabled={!redirectRef.current}  aria-label="Go to website" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(messages,"samedevice")}</Button></p>
            :
            (sameDeviceRef.current===false && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"differentdevice")}</p>)
            }
          {/* {redirectRef.current && <p style={{ margin: '5px' }}>   <Button tabIndex="0"  aria-label="Go to website" style={{color: backgroundColor, backgroundColor: color,fontSize:'16px', padding:'10px 15px', border:'1px solid #ccc', borderRadius:'border-radius: 4px', margin:'2px 0'}} onClick={handleRedirect}>{getMessageByKey(messages,"samedevice")}</Button></p>}   */}
        </div>
      )}

      {(!areBothCamerasAvailable || !initialized || !emailVerifiedRef.current) && <div className="session-starting">
        {logoRef.current && <img className="logo" src={logoRef.current} alt="checkid" />}
        {emailVerifiedRef.current !== false && areBothCamerasAvailable !== false && <p><div className="init-loader"></div></p>}
        {areBothCamerasAvailable === null && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"checking")|| "Checking device to make sure it can perform the verification"}</p>}
        {areBothCamerasAvailable === false && emailVerifiedRef.current !== false && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"nocamera")|| "To complete this verification you need a smart phone or a tablet with a front and back facing camera"}</p>}
        {areBothCamerasAvailable === true && !initialized && emailVerifiedRef.current !== false && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"initialize") || "Initializing CheckID"}</p>}
        {emailVerifiedRef.current === false && <p style={{ margin: '5px' }}>{getMessageByKey(messages,"nocheckid")|| "We could not initialize VerifyID.The session has either expired or never existed"}</p>}
      </div>}

      {images.faceImage && images.faceImage !== "" && !showCam && (
        <div className="webcam-container">
          <Card color="secondary" outline>
            <img  alt="Captured Face" src={images.potraitImage} className="webcam" 
            style={{width:'80vw',top:'5vh', left:'10vw'}}
 />
            <CardBody className="circlebody">
              <CardText tag="h4">
                Next step is to check if this is a valid ID.
              </CardText>
              <Button tabIndex="0" aria-label="Validate selfie" className="startbutton" onClick={() => setImages(null)}>
              {getMessageByKey(messages,"validate")}
              </Button>
              <Button tabIndex="0" aria-label="Take selfie again" className="startbutton" onClick={startCapture}>
              {getMessageByKey(messages,"restart")}
              </Button>
            </CardBody>
          </Card>
        </div>
      )}

      {showCam && (isAndroid || emailVerifiedRef.current || emailVerified) && (
        <div>

          <Card className="webcam-container" outline color="secondary">
          {/* {showOutlineRef.current && <img className="face-line" src="/outline.png" alt="faceoutline"></img>} */}
            {!doccaptureRef.current && !selfieImage.current && (
              <Webcam
                audio={false}
                height={480 * 2}
                width={640 * 2}
                mirrored={true} 
                videoConstraints={videoConstraints}
                controls={false} // Disable default controls
                playsInline // Adding this can help on iOS
                ref={webcamRef}
                className="webcam"
                id="selfiecam"
              />
            )}
            {/* <img className="peephole" src="/peephole.png" alt="checkid" /> */}

            {selfieImage.current && (
              <img
                alt="Captured Face"
                src={selfieImage.current}
                className="webcam"
                style={{width:'100vw'}}
              />
            )}

            <div className="canvas-container"><canvas className="canvas" ref={canvasRef} /></div>
            <LivenessAnimation canvasRef={canvasRef} start={animationStart} />

            {numberChallengeRef.current && numberVerifiedRef.current === null && (
              <div className="challenge-container"><DigitInput
                correctNumber={correctNumber}
                onVerified={onVerified}
                onClose={closeOverlay}
                messages={messages}
                setMessages={setMessages}
              /></div>
            )}

            
            <CardBody className="cardbody">
            {showLoader.current && 
            <p style={{display:'flex', justifyContent:"center"}}><div className="checkidloader"></div></p>
             }
              <p dangerouslySetInnerHTML={{ __html: message }} />

              

              {doccaptureRef.current && props.type !== "login" && (
                <Button
                  id="checkId"
                  tabIndex="0"
                   aria-label="Proceed to ID capture"
                  className="startbutton"
                  onClick={() => { setFacingMode("environment"); navigate("/doccapture/" + uniqueId) }}
                >
                 {getMessageByKey(messages,"next")}
                </Button>
              )}

                 {selfieImage.current && !aliveRef.current && <Button
                    id="retakeselfie"
                     aria-label="Retake selfie"
                    className="startbutton"
                    onClick={startCapture}
                  >
                   {getMessageByKey(messages,"retake")}
                  </Button> }
                
                  {selfieImage.current && !aliveRef.current && <Button
                  tabIndex="0"
                    id="validateselfie"
                     aria-label="Check Selfie"
                    className="startbutton"
                    onClick={checkSelfie}
                  >
                    {getMessageByKey(messages,"validate")}
                  </Button>}

              {(numberVerified === true || !numberChallengeRef.current) && selfieRef.current === null && !retakeSelfie.current && <Button tabIndex="0"  aria-label="Take Selfie" id="checkSelfie" className="startbutton" onClick={startCapture}>{getMessageByKey(messages,"capture")}</Button>}
            </CardBody>
          </Card>
        </div>
      )}
<div><a className="start-over" href={currentUrl}>{getMessageByKey(messages,"startover")|| "Start over"}</a></div>
      <div><img className="timer-image" src={logo} alt="checkid" /></div>
    </div>
  );
};

export default WebcamCapture;
