import { CaptureVisionRouter} from "dynamsoft-capture-vision-router"
import { DDV } from "dynamsoft-document-viewer"
// import { LicenseManager } from "dynamsoft-license"
import { mobileCaptureViewerUiConfig } from "./MobileCaptureViewerUIConfig"
import { initDocDetectModule } from "../initDocDetectModule"
import { CoreModule } from "dynamsoft-core"
import {LicenseManager } from "dynamsoft-license"
import "dynamsoft-document-viewer/dist/ddv.css"
import 'dynamsoft-document-normalizer';
import { isMobile } from "react-device-detect"
// import IDFrameCanvas from './IDFrameCanvas';
import { useEffect, useRef,useState } from "react"

import {Button, Card,CardBody,CardText,CardTitle,CardSubtitle} from 'reactstrap';
import {FaCheck, FaUndo} from 'react-icons/fa';
//import "../../node_modules/dynamsoft-document-viewer/dist/ddv.css"; 
import "../css/index.css"

// dynamsoft-document-viewer/dist/ddv.css"
let viewer;
const newPageStyle = {
    border: "2px dashed red",
    background: "grey",
    innerHeight: "50px"
};
const newViewerConfig = {
    pageStyle: newPageStyle,
};




function base64ToBlob(base64, contentType) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}



function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

    
    // Any other code that needs to run after setting the engineResourcePath
//CaptureVisionRouter.engineResourcePath = '../../node_modules/dynamsoft-capture-vision-router/dist';
export default function CaptureViewer(props) {
    
  const [captureViewer, setCaptureViewer] = useState(null);
  const [initialized,setInitialized]=useState(false);
  const [dynamsoftError, setDynamsoftError] = useState(false);
  const [canvasElement, setCanvasElement] = useState(null);
  const canvasRef = useRef(null);
  const initializedRef = useRef(false);
  const checkMrzRef = useRef(false);
  const fileInputRef = useRef(null); // Ref for file input


  const [showFirstImage, setShowFirstImage] = useState(true);

  function getMessageByKey(messages1, key) {
    const messageObject = messages1.find((message) => message.hasOwnProperty(key));
    return messageObject ? messageObject[key] : null;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFirstImage(false);
    }, 2500); // 5000 milliseconds = 5 seconds

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, [initialized]);



  // useEffect(() => {

  //     const videoElement = document.querySelector('video[id^="ddv-camera-video-"]');
  //     const targetElement = document.querySelector('.capturebody.card-body');
  //     canvasRef.current = document.querySelector('canvas[id^="ddv-camera-canvas-"]');
  //     setCanvasElement(canvasElement);
 
  //     console.log('Video element:', videoElement); // Debugging line
  //     if (videoElement) {
  //      // alert(videoElement.height);
  //       canvasRef.current.height= videoElement.clientHeight;
  //       if (targetElement) {
          
  //         targetElement.style.top = `${videoElement.clientHeight -80}px`;
          
          
  //     }     
      
  //   }
  //   targetElement.style.display = `block`;
 



  // }, [initialized]);
  const init = async (props) => {
  // CoreModule.engineResourcePaths = {
  //   rootDirectory: "https://cdn.jsdelivr.net/npm/", 
  //   core: "dynamsoft-core@3.0.30/dist/", 
  //   cvr: "dynamsoft-capture-vision-router@2.0.30/dist/", 
  //   ddn: "dynamsoft-document-normalizer@2.0.20/dist/", 
  //   license: "dynamsoft-license@3.0.20/dist/", 
  //   dip: "dynamsoft-image-processing@2.0.30/dist/",
  //   std: "dynamsoft-capture-vision-std@1.0.0/dist/"
  // };

        // CoreModule.engineResourcePaths = {
      //   std: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.0.0/dist/",
      //   dip: "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.0.30/dist/",
      //   core: "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.33/dist/",
      //   license: "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.40/dist/",
      //   cvr: "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.32/dist/",
      //   dbr: "https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.0.21/dist/",
      //   dce: "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.1/dist/"
      // };
      if(!props.licenseInit){
        try {
  CoreModule.engineResourcePaths.core = "https://cdn.jsdelivr.net/npm/dynamsoft-core@3.0.33/dist/";
  CoreModule.engineResourcePaths.cvr = "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.0.32/dist/";
  CoreModule.engineResourcePaths.ddn = "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.0.20/dist/";
  CoreModule.engineResourcePaths.license = "https://cdn.jsdelivr.net/npm/dynamsoft-license@3.0.40/dist/";
  CoreModule.engineResourcePaths.dip = "https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.0.30/dist/";
  CoreModule.engineResourcePaths.std = "https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.0.0/dist/";
  CoreModule.engineResourcePaths.dbr ="https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.0.21/dist/";
  CoreModule.engineResourcePaths.dce= "https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.1/dist/";


  //await LicenseManager.initLicense('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyNTAzNTM1LTEwMjg1ODk4OSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMjUwMzUzNSIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi0xMTE4Mzg5OTU0fQ==',true)
   
  await LicenseManager.initLicense('f0114uwAAAD/smRHQOrVzaVY/RuqoOiINZtHeBPeQLH0h+EAMbpe0l7EKLl/QQZx0U2/vQRb2KRNyaOoyhTMz46XtI5YrIWBX//U9rkb9/dilZe2X5+M9T6/+OirYoIgEUkADOVACnnrzbTsKq3+omj4anydF;f0068MgAAAAMd6aLIiS6S756zQ0axSnM7b468J2a81Di8bp0/8i68h9zGlu0MdzPPmPcCiGf4jMjeKE+hMBXPPerqh0ELM4c=')
  
  console.log("PropsDL",props.dl);
  DDV.Core.license = 'f0114uwAAAD/smRHQOrVzaVY/RuqoOiINZtHeBPeQLH0h+EAMbpe0l7EKLl/QQZx0U2/vQRb2KRNyaOoyhTMz46XtI5YrIWBX//U9rkb9/dilZe2X5+M9T6/+OirYoIgEUkADOVACnnrzbTsKq3+omj4anydF'

   CoreModule.loadWasm(['DBR','DDN']).catch((ex) => {
    let errMsg;
    if (ex.message?.includes('network connection error')) {
      errMsg =
        'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.';
    } else {
      errMsg = ex.message || ex;
    }
    console.error(errMsg);
    //alert(errMsg);
  });

    DDV.Core.engineResourcePath = '/ddvengine';
    DDV.Core.loadWasm();

    props.setLicenseInit(true);

  } catch (e) {
    console.error("Error initializing Dynamsoft", e);
    setDynamsoftError(true); // Set the error state if initialization fails
  }


}
  //  props.dl.dc =


  //await LicenseManager.initLicense(props.dl.dc,true);
try {
        //
     await DDV.Core.init();
        
        // console.log("set CVR License and about to set Capture Vision Resource Path")
        // CaptureVisionRouter.engineResourcePath = 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router-for-ddn2.0.11@2.0.11/dist/';
        //     await CaptureVisionRouter.preloadModule(['DDN']);
        //     console.log("set CVR License and about to set Capture Vision Resource Path")

        const router = await CaptureVisionRouter.createInstance();



 

        await initDocDetectModule(DDV, router);
      
         viewer = new DDV.CaptureViewer({
          container: 'viewerContainer',
          uiConfig : mobileCaptureViewerUiConfig,
          viewerConfig: {
            acceptedPolygonConfidence: 60,
            enableAutoCapture: false,
            enableAutoDetect:false,
            quadSelectionStyle: {
              background: "rgba(255,255,255,0)",
              border: "5px solid yellowgreen",
              ctrlBackground: "yellowgreen",
              ctrlBorder: "2px solid yellowgreen",
              ctrlBorderRadius: "50%",
              ctrlHeight: "1px",
              ctrlWidth: "10px",
              invalidBorderColor: "red",
              invalidCtrlBorderColor: "red",
          }
            
          }
        });
//         const canvasStyle = viewer.getStyle("canvasStyle");

//   // Modify the style object.
 
//   canvasStyle.height= "100%";
//   canvasStyle.position = "absolute";
//   canvasStyle.background= "transparent";

//   // Update canvas style;
//   viewer.updateStyle("canvasStyle", canvasStyle);

        // viewer.updateStyle("canvasStyle", {
        //     // background: "red",
        //     // border: "1px solid green",
        //     // height:"50%",
        //     width: "100%",
        //     height:"50% !important"

        // });
       
        setCaptureViewer(viewer);
        
      //  document.getElementsByTagName('video')[0].style.height='auto';
        viewer.play({
          resolution: [ 480*2,640*2]
        });
        setInitialized(true);
        viewer.on('captured', async (e) => {
          const pageData = await viewer.currentDocument.getPageData(e.pageUid);
         // const faceImage = URL.createObjectURL(base64ToBlob(props.images.faceImage, 'image/jpeg'));
          // props.setImages({
          //   originalImage: URL.createObjectURL(pageData.raw.data),
          //   detectedImage: URL.createObjectURL(pageData.display.data),
          //   faceImage : faceImage
          // });

          console.log("Check MRZ in Capture",checkMrzRef.current);
          if(checkMrzRef.current){
           console.log("MRZ Images will bea dded");
            const originalMRZImage= URL.createObjectURL(pageData.raw.data, 'image/jpeg',1.0);
            const detectedMRZImage=URL.createObjectURL(pageData.display.data, 'image/jpeg',1.0);
            props.setImages((prevImages) => ({
                ...prevImages,
                originalMRZImage,
                detectedMRZImage
            }));

          } else {
          

            let faceImage;
            let potraitImage;

            if (isValidUrl(props.images.faceImage)) {
              faceImage = props.images.faceImage; // Use it as is
            } else {
              faceImage = URL.createObjectURL(base64ToBlob(props.images.faceImage, 'image/jpeg'),1.0); // Convert to URL
            }

            if (isValidUrl(props.images.potraitImage)) {
              potraitImage = props.images.potraitImage; // Use it as is
            } else {
              potraitImage = URL.createObjectURL(base64ToBlob(props.images.potraitImage, 'image/jpeg'),1.0); // Convert to URL
            }


          props.setImages({
            originalImage: URL.createObjectURL(pageData.raw.data, 'image/jpeg',1.0),
            detectedImage: URL.createObjectURL(pageData.display.data, 'image/jpeg',1.0),
            faceImage : faceImage,
            potraitImage: potraitImage
          });
        }





         props.setShowCaptureViewer(false);
        });
      } catch (error) {
        console.error("Failed to initialize CaptureViewer", error);
        setDynamsoftError(true);
      }

    }

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        let faceImage;
        let potraitImage;

        if (isValidUrl(props.images.faceImage)) {
          faceImage = props.images.faceImage; // Use it as is
        } else {
          faceImage = URL.createObjectURL(base64ToBlob(props.images.faceImage, 'image/jpeg'),1.0); // Convert to URL
        }

        if (isValidUrl(props.images.potraitImage)) {
          potraitImage = props.images.potraitImage; // Use it as is
        } else {
          potraitImage = URL.createObjectURL(base64ToBlob(props.images.potraitImage, 'image/jpeg'),1.0); // Convert to URL
        }


      props.setImages({
        originalImage:url,
        detectedImage: url,
        faceImage : faceImage,
        potraitImage: potraitImage
      });
        props.setShowCaptureViewer(false);
      }
    };
    
    const triggerFileInput = () => {
      fileInputRef.current.click(); // Trigger the hidden file input
    };

    const playViewer= () =>{
      if (dynamsoftError || !isMobile ) {
        triggerFileInput(); // Trigger file upload if there's an error
      } else {
      viewer.capture();
      }
      
    }

    useEffect(() => {
       init(props); 
      // if(props.apiKey){
      // init(props);
      // } else {
      //   setInitialized(true);
      //   props.setTimeLeft(0);
      //   props.setEndSession(true);
      // }
     
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.apiKey]);

    useEffect(() => {
        
        if(props.showCaptureViewer) {
          console.log("props.mrz",props.checkMRZ);
          checkMrzRef.current = props.checkMRZ;

          try{
            captureViewer?.currentDocument.deleteAllPages();
          } catch (e) {
            console.log("Error deleting pages",e);
            captureViewer?.stop();
          }
            captureViewer?.play();
        } else {
            captureViewer?.stop();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.showCaptureViewer]);

    return (

//         <div>



            <div className="capture-container" style={props.showCaptureViewer ? {} : { display: 'none' }} id="viewerContainer">
                          {/* {canvasRef.current && <IDFrameCanvas canvasRef={canvasRef} />} */}
                          <Card   color="secondary" >
                          <p><div className="d-loader"></div></p>
                          {props.isEnvironmentCameraAvailable && showFirstImage && <img className="idCard" src="/id-front.png" alt="ID Front" />}
                 <CardBody className="capturebody">
    {/* <CardTitle tag="h4">
    <p>Scan the front of your ID</p>
    </CardTitle> */}

    <CardText tag="h5">
      <p>{getMessageByKey(props.messages,"placeid")}</p>
    </CardText>
    <Button className="startbutton" onClick={playViewer}>
    {getMessageByKey(props.messages,"capture")}
    </Button>
    <input
  type="file"
  accept="image/*"
  capture="environment"
  onChange={handleFileChange}
  ref={fileInputRef}
  style={{ display: "none" }} // Hidden file input
/>

  </CardBody>
</Card>

{((!captureViewer || !initialized )&& props.timeLeft > 0) && <div className="session-starting">
        <img className = "logo" src={props.logo} alt="checkid"/>   
       <p><div className="init-loader"></div></p>
        <p style={{margin:'5px'}}>{getMessageByKey(props.messages,"initializecapture")}</p>
      </div> } 
                {/* </div> */}
                <div><img className="timer-image" src={props.logo} alt="checkid" /></div>
            </div>







    )
}