import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Card,CardBody,CardText,CardTitle,CardSubtitle, Spinner} from 'reactstrap';
import {FaCheck, FaUndo} from 'react-icons/fa';
import  axios from 'axios';
import {BlockBlobClient} from '@azure/storage-blob';
import SSNInput from './SSNInput';
import AddressInput from './AddressInput';
import Cookies from 'js-cookie';

import '../css/index.css';
import { ImageEditorView } from 'dynamsoft-camera-enhancer';
export default function ImageContainer(props) {
  const ENDPOINT = process.env.REACT_APP_API_URL;
    let navigate = useNavigate();
    const [idMatch, setIDMatch]= useState(null);
    const [checking,setChecking]= useState(false);
    const imagedataRef= useRef(null);
    const [showAddressInput, setShowAddressInput] = useState(false);
    const addressVerifiedRef = useRef(null);
    const [showSSNInput, setShowSSNInput] = useState(false);
    const ssnVerifiedRef = useRef(null);
    const fullnameRef=useRef("");
    const messageRef = useRef("");
    const mrzRef = useRef(null);

    useEffect(() => {
      const videoElement = document.getElementById('idimage');
      const targetElement = document.getElementById('imagebody');
      targetElement.style.display = 'none';
      if (!videoElement || !targetElement) {
        console.error('Required elements not found');
        return;
      }
    
      const handleLoadedMetadata = () => {
        console.log('Video metadata loaded');
        targetElement.style.top = `${videoElement.clientHeight + 20}px`;
        targetElement.style.display = 'block';
      };
    
      // Check if video metadata is already loaded
      if (videoElement) {
        handleLoadedMetadata();
      } else {
        // Add event listener for loadedmetadata
        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      }
    
      // Cleanup function to remove event listener if necessary
      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }, []);

    // useEffect(() => {

    //   console.log("layout")
    //   const videoElement = document.getElementById('idimage');
    //   const targetElement = document.querySelector('.imagebody');
    
    //   console.log('Video element:', videoElement); // Debugging line
    //   if (videoElement) {
    
    //     if (targetElement) {
         
    //       targetElement.style.position = 'absolute';
    //       targetElement.style.top = `${videoElement.clientHeight+30}px`;
          
          
    //   }     
      
    // }
    // targetElement.style.display = `block`;
    
    
    const convertObjectUrlToBase64 = async (objectUrl) => {
      try {
          // Fetch the blob data from the object URL
          const response = await fetch(objectUrl);
          const blob = await response.blob();
    
          // Convert blob to base64
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
              reader.onloadend = () => {
                  const base64String = reader.result.split(',')[1]; // Remove the data prefix
                  resolve(base64String);
              };
              reader.onerror = (error) => reject(error);
              reader.readAsDataURL(blob); // Read blob as data URL (base64)
          });
      } catch (error) {
          console.error('Error converting Object URL to base64:', error);
      }
    };
    
    // }, []);
    // const [checked,props.setChecked] =useState(false);
    async function uploadBlob(blobUrl,uploadUrl,uniqueId,verified, data,email){
      const response = await fetch(blobUrl);
      const blobData = await response.blob();
      const blockBlobClient = new BlockBlobClient(uploadUrl);
      const uploadOptions = {
        blobHTTPHeaders: {
            blobContentType: 'image/jpeg'
        },
    };
    if (!props.faceOnly) {
        data.ocr.documentName = data.documentName
        if(email){
          uploadOptions.metadata = {email:email,iddata:JSON.stringify(data.ocr)};
        } else {
        uploadOptions.metadata = {iddata:JSON.stringify(data.ocr)};
        }
      }

    await blockBlobClient.uploadData(blobData,uploadOptions);

    let tags = {};
    

    if(email ) {
      tags.email = email.replace("@", "=");
    }

    tags.referenceid = uniqueId;
    // tags.type = data.documentName;
    tags.verified = verified ? "yes":"no";
    tags.addressverified = data.ocr.addressverified ? "yes":"no";
    tags.ownerverified = data.ocr.ownerverified? "yes":"no";
      await blockBlobClient.setTags(tags);
    
    
    }



    async function uploadDescriptorBlob(base64Descriptor, uploadUrl,uniqueId,verified, data,email) {
      // Convert Base64 string to buffer
      const descriptorBuffer = Buffer.from(base64Descriptor, 'base64');
    
      // Create a BlockBlobClient
      const blockBlobClient = new BlockBlobClient(uploadUrl);
      const uploadOptions = {
        blobHTTPHeaders: {
            blobContentType: 'application/octet-stream'
        },
    };
    uploadOptions.metadata = {iddata:JSON.stringify(data.ocr)};
      // Upload the descriptor as binary data
      await blockBlobClient.uploadData(descriptorBuffer, uploadOptions);
    
     

      
      let tags = {};
      
  
      if(email ) {
        tags.email = email.replace("@", "=");
      }
  
      tags.referenceid = uniqueId;
      tags.type = data.documentName;
      tags.verified = verified ? "yes":"no";
        await blockBlobClient.setTags(tags);
    }

    function getMessageByKey(messages1, key) {
      // console.log(messages1);
      // console.log(key);
      const messageObject = messages1.find((message) => message.hasOwnProperty(key));
      return messageObject ? messageObject[key] : null;
    }
    
    function base64ToBlob(base64, contentType) {
      const byteString = atob(base64.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: contentType });
    }

    const normalizeContent = (content, property) => {
      if (property.includes('Date')) {
        // Assuming MRZ date format (YYMMDD) for date properties
        if (/^\d{6}$/.test(content)) {
          // Extract year, month, and day components
          let year = parseInt(content.substring(0, 2), 10);
          const month = content.substring(2, 4);
          const day = content.substring(4, 6);
    
          // Determine the correct century
          const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
          const bufferYears = 20; // Buffer for future-dated documents; adjust as needed
          year += (year <= currentYear + bufferYears) ? 2000 : 1900;
    
          return `${year}-${month}-${day}`;
        } else if (content.split(' ').length === 3) {
          // Handle non-MRZ date formats, assuming "DD MMM YYYY"
          const parts = content.split(' ');
          const year = parts[2];
          const month = parts[1].toUpperCase();
          const day = parts[0];
          const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
          const monthIndex = months.indexOf(month) + 1;
          console.log(`${year}-${monthIndex < 10 ? `0${monthIndex}` : monthIndex}-${day.length < 2 ? `0${day}` : day}`)
          return `${year}-${monthIndex < 10 ? `0${monthIndex}` : monthIndex}-${day.length < 2 ? `0${day}` : day}`; // Ensure day is two digits
        }
      }   
    
      // Normalize nationality
      if (property === 'Nationality' || property === 'CountryRegion') {
        const trimmedContent = content.trim().toUpperCase(); // Trim and then convert to uppercase
        return trimmedContent === 'UNITED STATES OF AMERICA'? 'USA' : trimmedContent;
      }
    
      return content.toUpperCase(); // Default normalization to uppercase
    };
    
    async function checkId(firstFaceImageBase64, idImageBase64, rawBase64) {
      try {
        console.log(rawBase64);
        const options = {
          method: 'POST',
          url: ENDPOINT + '/api/compare-face',
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${props.apiKey}`
          },
          data: {
            uniqueid: props.uniqueId, 
            image1: firstFaceImageBase64,
            image2: idImageBase64,
            image3: rawBase64,
            checkid: true
          }
        };
    
        const response = await axios.request(options);
        console.log(response.data);
    
        // Check if the response indicates failure
        if (!response.data.success) {
          // Use the message from the response
          props.setInvalidMessage(response.data.message || 'ID verification failed.');
          messageRef.current = response.data.message || 'ID verification failed.';
          return false;
        }
    
        // If successful, return the data
        return response.data;
        
      } catch (error) {
        console.error('Error checking ID:', error);
    
        // Display error message if available, otherwise a generic error message
        const errorMessage = error.response && error.response.data && error.response.data.message 
          ? error.response.data.message 
          : 'An unexpected error occurred during ID verification.';
    
          props.setInvalidMessage( error.response.data.message  || 'ID verification failed.');
          messageRef.current =  error.response.data.message  || 'ID verification failed.';
        return false;
      }
    }
    


    async function checkMRZ(mrzBase64,mrzoBase64, licenseOcrData) {  
      try {
      
        console.log(mrzBase64);
        const options = {
          method: 'POST',
          url: ENDPOINT + '/api/check-mrz',
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${props.apiKey}`
  
          },
          data: {
            uniqueid : props.uniqueId, 
            image2: mrzBase64,
            image3: mrzoBase64,
            licenseOcrData: licenseOcrData
          }
        };

        const response = await axios.request(options);
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error('Error checking MRZ:', error);
        return false;
      }
    }



    const checkExpirationDate = async (data) =>{

      //console.log(licenseData);
     // console.log(data);


    const now = new Date();

    // Convert the 'value' from the DateOfExpiration object to a date object
    const expirationDate = new Date(data);
    
    // Check if the current date and time is after the expiration date
    const hasExpired = now > expirationDate;
    
    if (hasExpired) {
      console.log("The date has expired.");
      return true;
    } else {
      console.log("The date has not expired.");
      return false;
    }

    
 
  
  }


  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };
  
  const wait = async (time) => {
  
    await delay(time); // Wait for 1 second
  };

    const detectFaces = async () => {
      //   alert("detect face")
     // alert(JSON.stringify(props.images));
      setChecking(true);
      props.setChecked(false);
        // alert("got image");
        console.log("Detected Image",props.images.detectedImage);
        console.log("Detected MRZImage",props.images.detectedMRZImage);
        async function convertBlobUrlToBase64(blobUrl) {
         try {
           const response = await fetch(blobUrl);
           const blob = await response.blob();
       
           return new Promise((resolve, reject) => {
             const reader = new FileReader();
             reader.readAsDataURL(blob);
             reader.onloadend = function() {
               const base64data = reader.result;
               resolve(base64data.split(',')[1]);
             };
             reader.onerror = reject;
           });
         } catch (error) {
           console.error('Error converting blob URL to base64:', error);
           throw error;
         }
       }
       function flattenData(data, prefix = '', parentConfidence = 1) {
         let rows = [];
       
         for (const [key, value] of Object.entries(data)) {
           // Handle object with kind, content, confidence, etc.
           if (value && typeof value === 'object' && value.content) {
             const confidence = value.confidence || parentConfidence; // Use parent's confidence if not present
             rows.push({
               property: prefix + key,
               content: value.content,
               confidence: (confidence * 100).toFixed(2) + '%'
             });
           }
       
           // Recursively process nested objects
           if (value && typeof value === 'object' && value.properties) {
             rows = rows.concat(flattenData(value.properties, prefix + key + '-', value.confidence || parentConfidence));
           }
       
           // Recursively process arrays (like "values" in your JSON)
           if (value && Array.isArray(value.values)) {
             value.values.forEach((item, index) => {
               rows = rows.concat(flattenData(item.properties, `${prefix}${key}[${index}]-`, value.confidence || parentConfidence));
             });
           }
         }
       
         return rows;
       }


       console.log("running detect faces");
       if(props.images.detectedMRZImage && props.images.originalMRZImage){
        console.log("mrzfilesdetected");
        const mrzBase64= await convertBlobUrlToBase64(props.images.detectedMRZImage);
        const mrzoBase64= await convertBlobUrlToBase64(props.images.originalMRZImage);
        const mrzData= await checkMRZ(mrzBase64,mrzoBase64,props.licenseData.ocr);
        if (mrzData && mrzData.mrz) {
        // props.setLicenseData((prevData)=>{

        //   return {...prevData,mrz:mrzData.mrz}
        // })
        imagedataRef.current = props.licenseData;
        imagedataRef.current = {...imagedataRef.current,mrz:mrzData.mrz};
        console.log(imagedataRef.current);
        if(imagedataRef.current.mrz){
          fullnameRef.current= imagedataRef.current.mrz.givenNames + " " + imagedataRef.current.mrz.surname;
         } else {
          fullnameRef.current= imagedataRef.current.ocr.givenNames + " " + imagedataRef.current.ocr.surname;
         }
        setShowAddressInput(true);
      } else {

        props.setInvalidMessage("nomrzcode");
        messageRef.current=getMessageByKey(props.messages,"nomrzcode");
        setIDMatch(false);
        setChecking(false);
        props.setChecked(true); 

      }
    } else {

              const fiBase64=  await convertBlobUrlToBase64(props.images.faceImage)
              const diBase64=  await convertBlobUrlToBase64(props.images.detectedImage);
              const rawBase64=  await convertBlobUrlToBase64(props.images.originalImage);
            const imageMatch= await checkId(fiBase64,diBase64,rawBase64);


             if(imageMatch && imageMatch.data){
              
                      imagedataRef.current= imageMatch.data;
                       //alert(JSON.stringify(imagedata.data));


                       if(imagedataRef.current.documentName.toLowerCase().includes("united states - passport card") || imagedataRef.current.documentName.toLowerCase().includes("united states - employment authorization card") || imagedataRef.current.documentName.toLowerCase().includes("united states - permanent resident card")   ) {
                        props.setLicenseData(imagedataRef.current);   
                        props.setCheckMRZ(true);
                        props.setRetake(true);
                        await wait(2000) ;
                        props.setShowCaptureViewer(true);
                        
                      }


                      else if(imagedataRef.current.documentName.toLowerCase().includes("passport") && !imagedataRef.current.mrz){


                            props.setInvalidMessage("nomrzcode");
                            messageRef.current=getMessageByKey(props.messages,"nomrzcode");
                            setIDMatch(false);
                            setChecking(false);
                            props.setChecked(true); 
                        }
                      

                       if(imagedataRef.current.mrz){
                        fullnameRef.current= imagedataRef.current.mrz.givenNames + " " + imagedataRef.current.mrz.surname;
                       } else {
                        fullnameRef.current= imagedataRef.current.ocr.givenNames + " " + imagedataRef.current.ocr.surname;
                       }

                          
                          
                     //   if(imagedataRef.current.documentName.toLowerCase().includes("driv")  || imagedataRef.current.documentName.toLowerCase().includes("learn")  || imagedataRef.current.documentName.toLowerCase().includes("id card")){
                       if(imagedataRef.current.ocr.addressStreet){
                          props.setLicenseData(imagedataRef.current);     
                          setIDMatch(true);
                               props.setFirstFaceDescriptor(null);
                              setChecking(false);
                              props.setChecked(true);
                        } else {
                        // if(imagedataRef.current.documentName.toLowerCase().includes("passport") || imagedataRef.current.documentName.toLowerCase().includes("resident") ||imagedataRef.current.documentName.toLowerCase().includes("visa") || imagedataRef.current.documentName.toLowerCase().includes("border") || imagedataRef.current.documentName.toLowerCase().includes("new york city id card")){

                                  setShowAddressInput(true);
  

                        }
                 
                        // else {
                        //   props.setInvalidMessage("notaccepteddocument");
                        //   messageRef.current=getMessageByKey(props.messages,"notaccepteddocument");
                        //   setIDMatch(false);
                        //   setChecking(false);
                        //   props.setChecked(true);   


                        // }
             }
             else {
              //  props.setInvalidMessage("selfieidnotmatch");
              //  messageRef.current=getMessageByKey(props.messages,"selfieidnotmatch");
                 setIDMatch(false);
                 setChecking(false);
                 props.setChecked(true);
           //      alert("No match")
             }
            }
        
     
 }

    return (
        <div
            style={props.showCaptureViewer ? { display: 'none' } : {}}  
            className="webcam-container"
        >
          
            {/* {checking && !showSSNInput && !showAddressInput && <div class="loader"></div>} */}
          <Card
  color="secondary"
   outline
>
<img id="idimage" alt="" style={{width:'100%'}} src={props.images.detectedMRZImage || props.images.detectedImage} />
{/* <div className="image-checkmark-container">
        <FaCheck className="checkmark"/>
    </div> */}
  

  <CardBody id="imagebody" className="imagebody">
    {/* <CardTitle tag="h3">
    {props.checked ? idMatch ?  <p>Your ID matches your face and it looks real!</p>: <p>Sorry,couldn't verify your ID </p> : checking ? <p>Checking your ID</p> : <p>Check ID</p>}
    </CardTitle> */}
    {/* <CardSubtitle
      className="mb-2 text-muted"
      tag="h6"
    >
      Card subtitle
    </CardSubtitle> */}
   {checking && !showSSNInput && !showAddressInput && <p style={{display:'flex', justifyContent:"center"}}><div className="checkidloader"></div></p>}
    <CardText tag="h4">
    {showAddressInput &&
    <AddressInput apiKey={props.apiKey} uniqueId={props.uniqueId} fullname={fullnameRef.current} streetName={ imagedataRef.current && imagedataRef.current.ocr && imagedataRef.current.ocr.addressStreet} cityName={imagedataRef.current && imagedataRef.current.ocr && imagedataRef.current.ocr.addressCity} stateName={imagedataRef.current && imagedataRef.current.ocr && imagedataRef.current.ocr.addressJurisdictionCode} zipCode={imagedataRef.current && imagedataRef.current.ocr && imagedataRef.current.ocr.addressPostalCode} messages={props.messages} onVerified={(isVerified,{street,city,state,postalCode,owner,apn}) => {
            setShowAddressInput(false); // Hide Address input
            console.log(isVerified);
            console.log(owner);
            console.log(apn);
            imagedataRef.current.ocr.addressverified = isVerified;
            imagedataRef.current.ocr.ownerverified = owner || false;
            imagedataRef.current.ocr.apn = apn || "N/A";
            if (isVerified || !props.rejectaddress) {

                if(street) imagedataRef.current.ocr.addressStreet=street;
                if(state) imagedataRef.current.ocr.addressJurisdictionCode=state;
                if(city) imagedataRef.current.ocr.addressCity=city;
                if(postalCode) imagedataRef.current.ocr.addressPostalCode=postalCode;
                

                if(props.verifySsn){
                  setShowSSNInput(true);
                 } else {

                  if (props.verifiedIdOnly) {
  
                    return convertObjectUrlToBase64(props.images.potraitImage).then(base64Image => {
                      imagedataRef.current = {
                          ...imagedataRef.current,
                          photo: base64Image
                      };
                      props.setLicenseData(imagedataRef.current);

                      ssnVerifiedRef.current=true;
                      setIDMatch(true); // ID verification successful
                      props.setFirstFaceDescriptor(null);
                      setChecking(false);
                      props.setChecked(true);
                      props.setIdVerified(true);
                  });

                
                  } else {

                    return convertObjectUrlToBase64(props.images.potraitImage).then(base64Image => {
                      imagedataRef.current = {
                          ...imagedataRef.current,
                          photo: base64Image
                      };
                 
                      props.setLicenseData(imagedataRef.current);
                  let uploadPromises = [];
                  if(props.faceOnly){
                    uploadPromises = [
                      uploadBlob(props.images.faceImage, props.uploadUrls[0], props.uniqueId, true, imagedataRef.current, props.email),
                      // uploadDescriptorBlob(imagedataRef.current.descriptor, props.uploadUrls[3], props.uniqueId, true, imagedataRef.current)
                  ];
                  } else {
                   
                  uploadPromises = [
                    uploadBlob(props.images.faceImage, props.uploadUrls[0], props.uniqueId, true, imagedataRef.current, props.email),
                    uploadBlob(props.images.detectedImage, props.uploadUrls[1], props.uniqueId, true, imagedataRef.current),
                    uploadBlob(props.images.detectedMRZImage || props.images.detectedImage, props.uploadUrls[2], props.uniqueId, true, imagedataRef.current),
                    uploadBlob(props.images.potraitImage, props.uploadUrls[3], props.uniqueId, true, imagedataRef.current)
                ];
              }

               return Promise.all(uploadPromises)
                .then(() => {
                    // Handle success of all uploads
                    ssnVerifiedRef.current=true;
                    setIDMatch(true); // ID verification successful
                    props.setFirstFaceDescriptor(null);
                    setChecking(false);
                    props.setChecked(true);
                    props.setIdVerified(true);
                  
                })
                .catch((error) => {
                    // Handle errors from the upload process
                    console.error("Error during file uploads:", error);
                    ssnVerifiedRef.current=false;
                    props.setInvalidMessage("verificationfailed");
                    messageRef.current=getMessageByKey(props.messages,"verificationfailed");
                    setIDMatch(false); // ID verification successful
                    props.setFirstFaceDescriptor(null);
                    setChecking(false);
                    props.setChecked(true);
                    props.setIdVerified(false);
                });
              });
              }


                 }

    } else {

      addressVerifiedRef.current=false;
      props.setInvalidMessage("addressverificationfailed");
      messageRef.current=getMessageByKey(props.messages,"addressverificationfailed");
      setIDMatch(false); // ID verification successful
      props.setFirstFaceDescriptor(null);
      setChecking(false);
      props.setChecked(true);
      props.setIdVerified(false);

    }
    }} />
    }
    {showSSNInput && <SSNInput apiKey={props.apiKey} uniqueId={props.uniqueId} fullname={fullnameRef.current} messages={props.messages} onVerified={(isVerified,ssn) => {
            setShowSSNInput(false); // Hide SSN input
            if (isVerified) {

                imagedataRef.current.ocr.ssn= ssn;
                // Perform the uploads only after SSN is verified
              

                if (props.verifiedIdOnly) {
  
                  return convertObjectUrlToBase64(props.images.potraitImage).then(base64Image => {
                    imagedataRef.current = {
                        ...imagedataRef.current,
                        photo: base64Image
                    };
                    props.setLicenseData(imagedataRef.current);
                    ssnVerifiedRef.current=true;
                    setIDMatch(true); // ID verification successful
                    props.setFirstFaceDescriptor(null);
                    setChecking(false);
                    props.setChecked(true);
                    props.setIdVerified(true);
                });
              }
              else {

                return convertObjectUrlToBase64(props.images.potraitImage).then(base64Image => {
                  imagedataRef.current = {
                      ...imagedataRef.current,
                      photo: base64Image
                  };

                  props.setLicenseData(imagedataRef.current);

                let uploadPromises = [];
                if(props.faceOnly){
                  uploadPromises = [
                    uploadBlob(props.images.faceImage, props.uploadUrls[0], props.uniqueId, true,imagedataRef.current, props.email),
                    // uploadDescriptorBlob(imagedataRef.current.descriptor, props.uploadUrls[3], props.uniqueId, true, imagedataRef.current)
                ];
                } else {
                uploadPromises = [
                  uploadBlob(props.images.faceImage, props.uploadUrls[0], props.uniqueId, true, imagedataRef.current, props.email),
                  uploadBlob(props.images.detectedImage, props.uploadUrls[1], props.uniqueId, true, imagedataRef.current),
                  uploadBlob(props.images.detectedMRZImage || props.images.detectedImage, props.uploadUrls[2], props.uniqueId, true, imagedataRef.current),
                  uploadBlob(props.images.potraitImage, props.uploadUrls[3], props.uniqueId, true, imagedataRef.current)
              ];
            }

                return Promise.all(uploadPromises)
                .then(() => {
                    // Handle success of all uploads
                    ssnVerifiedRef.current=true;
                    setIDMatch(true); // ID verification successful
                    props.setFirstFaceDescriptor(null);
                    setChecking(false);
                    props.setChecked(true);
                    props.setIdVerified(true);
                  
                })
                .catch((error) => {
                    // Handle errors from the upload process
                    console.error("Error during file uploads:", error);
                    props.setInvalidMessage("verificationfailed");
                    messageRef.current=getMessageByKey(props.messages,"verificationfailed");
                    ssnVerifiedRef.current=false;
                    setIDMatch(false); // ID verification successful
                    props.setFirstFaceDescriptor(null);
                    setChecking(false);
                    props.setChecked(true);
                    props.setIdVerified(false);
                });
              });
              }
            } else {
              ssnVerifiedRef.current=false;
              props.setInvalidMessage("ssnverificationfailed");
              messageRef.current=getMessageByKey(props.messages,"ssnverificationfailed");
                setIDMatch(false); // SSN verification failed
                setChecking(false);
                props.setChecked(true);
                props.setIdVerified(false);
            }
        }} />}
    {props.checked ? idMatch ? (imagedataRef.current.documentName.toLowerCase().includes("driv") || imagedataRef.current.documentName.toLowerCase().includes("learn") ||  imagedataRef.current.documentName.toLowerCase() === "id card") ? <p>{getMessageByKey(props.messages,"scanbarcode")} </p>: <p>{getMessageByKey(props.messages,"idverified")} </p> :<p>{getMessageByKey(props.messages,"idnotverifiedretake")} </p> : checking ? showAddressInput? <p>{getMessageByKey(props.messages,"verifyaddress")}</p>  :showSSNInput ? <p>{getMessageByKey(props.messages,"verifyssn")}</p> : <p>{getMessageByKey(props.messages,"checkingid")}</p> : addressVerifiedRef.current===false? <p>{getMessageByKey(props.messages,"addressnotverified")}</p> : ssnVerifiedRef.current===false ? <p>{getMessageByKey(props.messages,"ssnnotverified")}</p> : <p>{getMessageByKey(props.messages,"startcheckingid")}</p>}
      {messageRef.current && <p>{messageRef.current}</p>}
    </CardText>

    {!checking && ssnVerifiedRef.current !==false && (!idMatch || props.checkMrz) && 
    <Button tabIndex="0"  aria-label="Retake ID Image" className="startbutton" onClick={() => {props.setChecked(false);messageRef.current="";props.setRetake(true);props.setShowCaptureViewer(true)}}>
   {getMessageByKey(props.messages,"retake")}
    </Button>}
    {!checking && !props.checked &&  props.images.detectedImage && !idMatch && <Button  tabIndex="0"  aria-label="Take ID Image" className="startbutton" onClick={() => detectFaces(props.images.detectedImage)}>
    {getMessageByKey(props.messages,"start")}
    </Button>}
    {!checking && idMatch && (imagedataRef.current.documentName.toLowerCase().includes("driv") || imagedataRef.current.documentName.toLowerCase().includes("learn")  || imagedataRef.current.documentName.toLowerCase() === "id card") && 
     <Button tabIndex="0"  aria-label="Go to Barcode Scanner" className="startbutton" onClick={()=>navigate('/docscanner/' + props.uniqueId)}>
      {getMessageByKey(props.messages,"start")}
    </Button>
}


  </CardBody>
</Card>
<div><a className="start-over" href={props.currentUrl}>{getMessageByKey(props.messages,"startover")|| "Start over"}</a></div>   
<div><img className="timer-image" src={props.logo} alt="checkid"/></div>
        </div>
    )
}